<template>
  <span class="flex flex-col max-w-[800px] animate-fadeIn">
    <div class="flex flex-col justify-center items-center text-center mb-12">
      <img :src="trophyImage" class="card-image" />
      <h1 class="mb-3">Congrats, you're all ready!</h1>
      <p class="text-lg mt-6">
        With Bolster you can build automated estimates to save hours on every project. You're taking
        the first step to <b>automate</b> your workflow. We've had you in mind with everything we've
        built.
      </p>
      <p class="text-lg mt-6">
        Our team comes from the construction world; <b>we speak your language</b>. This product is
        built better with the insights of our customers, and we can't wait for you to discover
        everything it has to offer.
      </p>
      <p class="text-lg mt-6 self-end">- The Bolster Team</p>
    </div>

    <div class="flex mt-3 justify-between">
      <div>
        <FormButton
          @click="$emit('lastPage')"
          ref="lastButton"
          text="Edit"
          leftArrow
          muted
          showLoading
        />
      </div>
      <div>
        <FormButton
          @click="completeOnboarding"
          ref="nextButton"
          text="Go to Bolster"
          rightArrow
          showLoading
        />
      </div>
    </div>
  </span>
</template>

<script>
import FormButton from '@/components/ui/FormButton.vue'
import trophyImage from '@/assets/3D_trophy.png'

export default {
  name: 'IntakeCompleted',
  components: {
    FormButton
  },
  data() {
    return {
      trophyImage
    }
  },
  methods: {
    async completeOnboarding() {
      const data = {
        company_has_onboarded: 1,
        company_onboarded_on_bolster_time_completed: new Date().valueOf()
      }
      await this.$store.dispatch('Company/submitOnboardingData', {
        companyId: this.$store.state.session.scope.company,
        data
      })
      // Refresh, which gets picked-up by bodies/IntakeForm, which then redirects to the pipeline
      this.$router.go()
    }
  }
}
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
.subtitle-text {
  font-size: 18px;
}
.card-image {
  min-width: 30%;
  max-width: 90%;
}
</style>
