<template>
  <span class="flex flex-col max-w-[800px] animate-fadeIn">
    <div class="mb-1">
      <h1 class="mb-1">Streamline your payments</h1>
      <p>
        Take advantage of our enhanced payment solution, designed to transform the way you handle
        transactions. With Bolster's payment feature, you can expect a seamless, efficient, and
        secure payment experience that empowers you to focus on what you do best—building.
      </p>
      <div v-if="!hasCounterparty || !hasBanking">
        <div class="flex flex-row gap-x-4 mt-6">
          <font-awesome-icon icon="shield-check" class="text-2xl mt-1" />
          <div class="flex flex-col">
            <p class="text-xl mb-1">Quicker Settlement</p>
            <p>
              Get your cash quicker with our faster settlement options. No more delays or cash flow
              interruptions - just fast, reliable access to your hard-earned funds.
            </p>
          </div>
        </div>

        <div class="flex flex-row gap-x-4 mt-6">
          <font-awesome-icon icon="coin-front" class="text-2xl mt-1" />
          <div class="flex flex-col">
            <p class="text-xl mb-1">Time-Saving Automation</p>
            <p>
              Say goodbye to manual tasks. There's no need to write checks or hand-deliver them to
              your banks or subcontractors. Funds are sent online and can be deposited immediately.
            </p>
          </div>
        </div>

        <div class="flex flex-row gap-x-4 mt-6">
          <font-awesome-icon icon="money-bill-transfer" class="text-2xl mt-1" />
          <div class="flex flex-col">
            <p class="text-xl mb-1">Convenient Payment Options</p>
            <p>
              Make it easy for your clients to settle invoices promptly, improving your cash flow
              and reducing administrative hassle. Give your clients the flexibility to pay from
              anywhere, at any time, on their devices.
            </p>
          </div>
        </div>

        <div class="flex flex-row gap-x-4 mt-6">
          <font-awesome-icon icon="file-invoice-dollar" class="text-2xl mt-1" />
          <div class="flex flex-col">
            <p class="text-xl mb-1">Integrated Billing & Collections</p>
            <p>
              Streamline your billing and collections process by centralizing it in one place.
              Easily manage your transactions within the platform, avoiding disruption and keeping
              organized.
            </p>
          </div>
        </div>

        <div class="mt-10">
          <btn
            :action="() => (moreDetails = !moreDetails)"
            severity="tertiary"
            class="w-full"
            size="lg"
          >
            {{ moreDetails ? 'Hide details' : 'More details' }}
          </btn>
          <TransactionFeeTable v-if="moreDetails" class="mt-6 animate-fadeIn" />
        </div>
      </div>

      <div v-else class="flex flex-col gap-4 mt-6 p-8 rounded shadow-md bg-flame-white">
        <div class="flex flex-row items-center gap-x-4">
          <font-awesome-icon :icon="['fas', 'hexagon-check']" class="text-4xl text-matcha-500" />
          <h1 class="!text-matcha-500">Bolster Verified</h1>
        </div>
        <p>
          You've successfully enrolled in our seamless, efficient, and secure payment experience
          that empowers you to focus on what you do best — building.
        </p>
        <p>
          It may take a few hours or days before your profile goes live and you're ready to receive
          payments. You can check your profile's status on the payments page.
        </p>
      </div>
    </div>

    <CompanyOnboarding />

    <div class="bg-surface-200 mt-10 h-px" />
    <div class="flex mt-3 w-100 justify-between">
      <div><FormButton @click="$emit('lastPage')" text="Previous" leftArrow muted /></div>
      <div class="flex flex-row gap-x-4">
        <div><FormButton v-if="!hasBanking" @click="openPaymentOnboarding" text="Enroll" /></div>
        <div v-if="hasCounterparty">
          <FormButton
            @click="$emit('nextPage')"
            text="Continue"
            rightArrow
            :muted="hasBanking ? false : true"
          />
        </div>
      </div>
    </div>
  </span>
</template>

<script>
import FormButton from '@/components/ui/FormButton.vue'
import CompanyOnboarding from '@/components/onboarding/CompanyOnboarding.vue'
import eventBus from '@/eventBus'
import TransactionFeeTable from '@/components/payments/counterparty/TransactionFeeTable.vue'

export default {
  name: 'IntakePaymentDetails',
  components: {
    FormButton,
    CompanyOnboarding,
    TransactionFeeTable
  },
  data() {
    return {
      moreDetails: false
    }
  },
  computed: {
    hasCounterparty() {
      return this.$store.state.session.company.counterparty_status !== 'u'
    },
    hasBanking() {
      return this.$store.state.session.company.counterparty_status === 'a'
    }
  },
  methods: {
    openPaymentOnboarding() {
      eventBus.$emit('toggleCompanyOnboarding')
    }
  },
  mounted() {
    // Automatically open to banking step after counterparty creation refresh
    if (this.hasCounterparty && !this.hasBanking) this.openPaymentOnboarding()
  }
}
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
.subtitle-text {
  font-size: 18px;
}
</style>
