<template>
  <div class="bg-surface-100 text-surface-950 bg-cover bg-center h-full">
    <ScrollContainer>
      <div class="mt-[7vw] mx-6">
        <IntakeNotAdmin v-if="!userIsAdmin" />
        <div v-else>
          <IntakeLanding v-if="page === 0" @nextPage="nextPage" :enableKYC="enableKYC" />
          <div v-if="page >= 1" class="flex flex-row justify-center gap-x-20 gap-y-10">
            <div />
            <IntakeNavigation
              v-if="page > 1 && page < 8"
              :page="page"
              :enableKYC="enableKYC"
              class="hidden 2xl:block"
            />
            <div>
              <IntakeAcceptTerms v-if="page === 1" @nextPage="nextPage" @lastPage="lastPage" />
              <PatriotActTerms v-if="page === 2" @nextPage="nextPage" @lastPage="lastPage" />
              <IntakeCompanyDetails
                v-if="page === 3"
                @nextPage="nextPage"
                @lastPage="lastPage"
                :object="this.$store.state.session.company"
              />
              <IntakeWorkDetails
                v-if="page === 4"
                @nextPage="nextPage"
                @lastPage="lastPage"
                :object="this.$store.state.session.company"
              />
              <IntakeCompanyDefaults
                v-if="page === 5"
                @nextPage="nextPage"
                @lastPage="lastPage"
                :object="this.$store.state.session.company"
              />
              <IntakeInviteUsers v-if="page === 6" @nextPage="nextPage" @lastPage="lastPage" />
              <IntakePaymentDetails v-if="page === 7" @nextPage="nextPage" @lastPage="lastPage" />
              <IntakeCompleted @lastPage="lastPage" v-if="page === 8" />
            </div>
            <IntakeHelp v-if="page > 2 && page < 8" class="hidden lg:block" />
          </div>
        </div>
      </div>
    </ScrollContainer>
  </div>
</template>

<script>
import IntakeNavigation from '@/components/intake/IntakeNavigation.vue'
import IntakeHelp from '@/components/intake/IntakeHelp.vue'
import IntakeLanding from '@/components/intake/IntakeLanding.vue'
import IntakeCompanyDetails from '@/components/intake/IntakeCompanyDetails.vue'
import IntakeWorkDetails from '@/components/intake/IntakeWorkDetails.vue'
import IntakeCompanyDefaults from '@/components/intake/IntakeCompanyDefaults.vue'
import IntakeInviteUsers from '@/components/intake/IntakeInviteUsers.vue'
import IntakePaymentDetails from '@/components/intake/IntakePaymentDetails.vue'
import IntakeCompleted from '@/components/intake/IntakeCompleted.vue'
import IntakeAcceptTerms from '@/components/intake/IntakeAcceptTerms.vue'
import PatriotActTerms from '@/components/intake/PatriotActTerms.vue'
import IntakeNotAdmin from '@/components/intake/IntakeNotAdmin.vue'

export default {
  name: 'IntakeFormBody',
  components: {
    IntakeNavigation,
    IntakeHelp,
    IntakeLanding,
    IntakeCompanyDetails,
    IntakeWorkDetails,
    IntakeCompanyDefaults,
    IntakeInviteUsers,
    IntakePaymentDetails,
    IntakeCompleted,
    IntakeAcceptTerms,
    PatriotActTerms,
    IntakeNotAdmin
  },
  data() {
    return {
      page: 0,
      lastPageIndex: 8,
      disableKYC: false
    }
  },
  computed: {
    enableKYC() {
      /**
       * Manual disabling of forced KYC onboarding during intake.
       * Setting disableKYC to true will disable the KYC step for all companies.
       */
      if (this.disableKYC) return false
      /**
       * Force KYC step when:
       * 1) Companies are in a supported country
       * 2) Companies have the "Payments - Adyen" module enabled (handle = "payments_v2")
       */
      const company = this.$store.state.session.company
      const allowedCountries = ['us', 'ca']
      return (
        allowedCountries.includes(company.country_abbr) && parseInt(company.aoModules.payments_v2)
      )
    },
    userIsAdmin() {
      const session = this.$store.state.session
      return session.user.user_admin_company_ids.includes(session.company.company_id.toString())
    }
  },
  async created() {
    /**
     * Initialize with an initial empty onboarding object inside of oMeta
     * ie. {..., "onboarding":{}}
     */
    const company = this.$store.state.session.company
    if (!company.oMeta || !company.oMeta.onboarding) {
      const data = {
        oMeta: { ...company.oMeta, onboarding: {} }
      }
      await this.$store.dispatch('Company/submitOnboardingData', {
        companyId: this.$store.state.session.scope.company,
        data
      })
    }
    /**
     * The page refreshes twice during KYC onboarding. Both of these times, we want to jump
     * back to payments after the page reloads. Upon reloading, the company counterparty
     * status will have changed, so we can use that as a check.
     */
    if (this.enableKYC && company.counterparty_status !== 'u' && company.oMeta.onboarding) {
      this.page = 8
    }
    /**
     * If the company has onboarded, send them to the pipeline
     */
    if (company.company_has_onboarded) {
      this.$router.push({ name: 'Pipeline' })
    }
  },
  methods: {
    nextPage() {
      // Skip KYC step if disabled
      if (!this.enableKYC && this.page === 5) {
        this.page = Math.min(this.page + 2, this.lastPageIndex)
      } else {
        this.page = Math.min(this.page + 1, this.lastPageIndex)
      }
    },
    lastPage() {
      // Skip KYC step if disabled
      if (!this.enableKYC && this.page === 8) {
        this.page = Math.max(this.page - 2, 0)
      } else {
        this.page = Math.max(this.page - 1, 0)
      }
    }
  }
}
</script>
